import React, {useState} from 'react'
import {Editor} from '@tinymce/tinymce-react'

import {deleteInfoItem, updateInfoData} from '../../../../../services/ApiClient'
import {useFormState} from '../../../../../hooks/useFormState'
import './EditInfoDataModal.css'
import InputFile from '../../../../Form/InputFile/InputFile'
import {app} from '../../../../../services/firebase'
import InputWithLabel from '../../../../Form/InputWithLabel/InputWithLabel'

function EditInfoDataModal({deleteItem, element, hideModal}) {

    const [registerError, setRegisterError] = useState(null)
    const [message, setMessage] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [fileSizeMessage, setFileSizeMessage] = useState('')

    const {state, onChange} = useFormState(
        {
            data: {
                id: element.id,
                icon_url: element.icon_url,
                item_tag: element.item_tag,
                item_tag_eng: element.item_tag_eng,
                item_description: element.item_description,
                item_description_eng: element.item_description_eng
            },
            error: {
                icon_url: true,
                item_tag: true,
                item_tag_eng: true,
                item_description: true,
                item_description_eng: true,
            },
            touch: {},
        },
        {
            icon_url: v => v.length,
            item_tag: v => v.length,
            item_tag_eng: v => v.length,
            item_description: v => v.length,
            item_description_eng: v => v.length,
        }
    )

    const {data, error} = state

    const deleteCarrouselItem = async (id) => {
        const updateData = await deleteInfoItem(id)
        deleteItem(updateData)
    }

    const editGoalItem = async (id) => {

        if (error.item_description === false || error.icon_url === false || error.item_description_eng === false || error.item_tag === false || error.item_tag_eng === false) {
            data.id = id
            try {
                await updateInfoData(data, id)
                    .then(updateData => {
                        deleteItem(updateData)
                    })
            } catch (err) {
                setRegisterError(err.response?.data?.message)
            }

        } else {
            setMessage('Por favor edite alguno de los campos')
        }
    }

    const handleBannerDescription = (e) => {
        data.item_description = e.target.getContent()
        error.item_description = false
    }

    const handleBannerDescriptionEng = (e) => {
        data.item_description_eng = e.target.getContent()
        error.item_description_eng = false
    }

    const onFileSelected = async (e) => {
        // Get file
        const file = e.target.files[0]

        if (file.size > 500000) {
            setFileSizeMessage("El tamaño de la imagen excede el máximo permitido (500KB), por favor optimícela y vuelva a intentar")
        } else {
            setIsDisabled(!isDisabled)
            // Create storage ref
            const storageRef = app.storage().ref()
            const filePath = storageRef.child('images/' + file.name)

            // Upload file
            await filePath.put(file)
                .then(() => {
                    //Se habilita el botón para subir el blog
                    setDisabled(!disabled)
                })
                .catch(err => {
                    setFileSizeMessage(err)
                })


            // Get file url
            const fileUrl = await filePath.getDownloadURL()
            data.icon_url = fileUrl
            setIsDisabled(false)
            error.icon_url = false
            setFileSizeMessage("Imagen cargada exitosamente")
        }
    }

    return (
        <div className="EditElementsModal">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-11 col-sm-8 EditElementsModal__container">
                        <span className="EditElementsModal__close" onClick={hideModal}></span>
                        <div className="col-sm-12">
                            <h1 className="EditInfoDataModal__ask">Editar beneficio</h1>
                            <div className="card">
                                <div className="card-body EditElementsModal__body">
                                    <div className="row align-items-center">
                                        <div className='col-12'>
                                            <p className="AdminEdit__form__label">
                                                Editar icono
                                            </p>
                                            <figure style={{width: "60px", height: "60px", borderRadius: "100px", background: "#0e95f2", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                <img src={element?.icon_url} onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt={element?.title} style={{width: "30px"}} />
                                            </figure>
                                            <InputFile
                                                value={element?.icon_url}
                                                onChange={onFileSelected}
                                                id="fileButton"
                                                name="icon_url"
                                                type="file"
                                                placeholder={element?.icon_url}
                                            />
                                        </div>

                                        {fileSizeMessage &&
                                            <div className='col-12'>
                                                <span className="AdminEdit__message m-0">{fileSizeMessage}</span>
                                            </div>
                                        }
                                        <div className="col-12 col-sm-6">
                                            <p className="AdminEdit__form__label">
                                                Editar tag
                                            </p>
                                            <InputWithLabel
                                                value={data?.item_tag}
                                                onChange={onChange}
                                                name="item_tag"
                                                type="text"
                                                cssStyle="form-control"
                                                placeholder={element?.item_tag}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <p className="AdminEdit__form__label en">
                                                Edit tag
                                            </p>
                                            <InputWithLabel
                                                value={data?.item_tag_eng}
                                                onChange={onChange}
                                                name="item_tag_eng"
                                                type="text"
                                                cssStyle="form-control"
                                                placeholder={element?.item_tag_eng}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <p className="AdminEdit__form__label">
                                                Editar descripción
                                            </p>
                                            <Editor
                                                initialValue={data?.item_description}
                                                onChange={handleBannerDescription}
                                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image',
                                                        'charmap print preview anchor help',
                                                        'searchreplace visualblocks code',
                                                        'insertdatetime media table paste wordcount'
                                                    ],
                                                    toolbar:
                                                        'bold',
                                                }}
                                            />
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <p className="AdminEdit__form__label en">
                                                Edit description
                                            </p>
                                            <Editor
                                                initialValue={data?.item_description_eng}
                                                onChange={handleBannerDescriptionEng}
                                                apiKey={process.env.REACT_APP_API_TINY_CLOUD}
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image',
                                                        'charmap print preview anchor help',
                                                        'searchreplace visualblocks code',
                                                        'insertdatetime media table paste wordcount'
                                                    ],
                                                    toolbar:
                                                        'bold',
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div onClick={() => editGoalItem(element._id)} className="leti-btn mt-0">Editar elemento</div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div onClick={() => deleteCarrouselItem(element._id)} className="leti-btn mt-0 delete">Eliminar elemento</div>
                                        </div>
                                        <div className="col-12">
                                            {registerError && <div className="alert alert-danger">{registerError}</div>}
                                            {message && <span className="AdminEdit__message m-0">{message}</span>}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EditInfoDataModal
