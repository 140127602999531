import React from 'react'
import './Banner.css'

function Banner() {

    return (
            <section className="container-fluis Banner">
                <div className="row">
                    <div className="col-12 col-sm-6 Banner__image" />
                </div>
            </section>
    )
}

export default Banner
