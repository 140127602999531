import './Clinical.css'
import React, {useState, useEffect} from 'react'
import {useAuthContext} from '../../../../../contexts/AuthContext'

import {getClinical} from '../../../../../services/ApiClient'
import {Fade} from 'react-awesome-reveal'

function Clinical() {

    const [clinicalData, setClinicalData] = useState([])
    const {language} = useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            const getClinicalData = await getClinical()
            setClinicalData(getClinicalData)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <section className="container BiocontrolledInfo-Clinical">
                <div className='row'>
                    <div className='col-sm-10 col-12'>
                        <Fade>
                            <h1>{language === 'ES' ? clinicalData?.title : clinicalData?.title_eng}</h1>
                        </Fade>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-10 col-12'>
                        <Fade>
                            <p dangerouslySetInnerHTML={{__html: language === 'ES' ? clinicalData?.description : clinicalData?.description_eng}} />
                        </Fade>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 BiocontrolledInfo-Clinical--image'></div>
                </div>
                <div className='row'>
                    <div className='col-sm-8 col-12 BiocontrolledInfo-Clinical--info' dangerouslySetInnerHTML={{__html: language === 'ES' ? clinicalData?.info : clinicalData?.info_eng}} />
                </div>
                <div className='row'>
                    <div className='col-12 BiocontrolledInfo-Clinical--logos'></div>
                </div>
                <div className='row justify-content-between'>
                    <div className='col-sm-6 col-12'>
                        <Fade>
                            <h1>{language === 'ES' ? clinicalData?.title_two : clinicalData?.title_two_eng}</h1>
                        </Fade>
                    </div>
                    <div className='col-sm-5 col-12'>
                        <Fade>
                            <span className='light' dangerouslySetInnerHTML={{__html: language === 'ES' ? clinicalData?.description_two : clinicalData?.description_two_eng}} />
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Clinical
