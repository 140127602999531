import './CheckBoxWithLabel.css';
import React, { useState } from 'react';

const CheckBoxWithLabel = ({ data, label, styleClass, onChange }) => {
    const [selectedValues, setSelectedValues] = useState([]);  // Estado interno para valores seleccionados

    const handleCheckboxChange = (event) => {
        const { value } = event.target;
        let updatedValues;
        
        if (selectedValues.includes(value)) {
            updatedValues = selectedValues.filter(val => val !== value);  // Desmarcar si ya está seleccionado
        } else {
            updatedValues = [...selectedValues, value];  // Agregar si no está seleccionado
        }

        setSelectedValues(updatedValues);  // Actualizar el estado interno
        onChange(updatedValues);  // Pasar los valores actualizados al padre
    };

    return (
        <div className="CheckBoxWithLabel">
            {label && <label className="label-title" htmlFor={label}>{label}</label>}
            <div className="CheckBoxWithLabel row">
                {data?.map((el, index) => (
                    <div key={index} className={`form-check ${styleClass ? styleClass : 'col'}`}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name={el}
                            id={el}
                            value={el}
                            onChange={handleCheckboxChange}
                            checked={selectedValues.includes(el)}  // Marcar si está en selectedValues
                        />
                        <label className="form-check-label" htmlFor={el}>
                            {el}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CheckBoxWithLabel;
