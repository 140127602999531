import {useState, useEffect} from 'react'
import './Marquee.css'
import React from 'react'
import {useAuthContext} from '../../../../../contexts/AuthContext'

import {getMarquee} from '../../../../../services/ApiClient'

function Marquee() {
    const [marqueeData, setMarqueeData] = useState([])
    const {language} = useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            const getMarqueeData = await getMarquee()
            setMarqueeData(getMarqueeData)
        }
        fetchData()
    }, [])

    return (
        <>
            <section className="container-fluid BiocontrolledInfo-Marquee">
                <div className='row'>
                    <div className='col-12'>
                        <div className="marquee">
                            <div className="marquee__content">
                                <p className="marquee__item">
                                    {language === 'ES' ? marqueeData?.text : marqueeData?.text_eng}
                                </p>
                                {/* Duplicamos el contenido para hacer el efecto infinito */}
                                <p className="marquee__item">
                                    {language === 'ES' ? marqueeData?.text : marqueeData?.text_eng}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Marquee
